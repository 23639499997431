import {
  setColor1,
  setMessageHistory,
  setNavigatorAvatar,
  setnavigatorName,
  setOrigin,
  setPowerappProfile,
  setUserInfo,
  setWikiInfo,
  updateApiToken,
} from '@/store';
import {
  IHistoryChat,
  TPowerappProfile,
  TUserInfo,
  TWikiInfo,
} from '@/types';
import eventBus from './event-bus';
import { updateIframePosition, TIframePostion } from './iframe-position';
import { handleChatHistory } from './message-history';

type TMessageEvents = {
  isWidget: boolean;
  message: TUserInfo | IHistoryChat[] | IHistoryChat | TIframePostion | TPowerappProfile | TWikiInfo | string;
  messageType: 'userInfo' | 'chatHistory';
}

export function messageEvents(data: TMessageEvents){
  const {
    isWidget,
    message,
    messageType,
  } = data
  if (!isWidget) return
  const messageActions = {
    activeChat: () => eventBus.emit('active-chat', message),
    activeChatMessagesUpdated: () => eventBus.emit('active-chat-messages-updated', message),
    activeChatUpdated: () => eventBus.emit('active-chat', message),
    apiTokenUpdated: () => updateApiToken(message as string),
    changeAvatar: () => setNavigatorAvatar(message as string),
    changeColor1: () => setColor1(message as string),
    changeName: () => setnavigatorName(message as string),
    chatHistory: () => setMessageHistory(message as IHistoryChat[]),
    iframePosition: () => updateIframePosition(message as TIframePostion),
    origin: () => setOrigin(message as string),
    powerappProfile: () => setPowerappProfile(message as TPowerappProfile),
    requestChatHistoryFromAPI: () => handleChatHistory(),
    userInfo: () => setUserInfo(message as TUserInfo),
    whiteLabelConfig: () => setPowerappProfile(message as TPowerappProfile),
    wikiInfo: () => setWikiInfo(message as TWikiInfo),
  }
  if(!messageActions[messageType]){
    throw new Error(`Método ${messageType} não encontrado`)
  }
  messageActions[messageType]()
}