<template>
  <section
    id="chat-box"
    class="flex flex-col flex-auto w-full"
    :style="chatBoxStyles"
  >
    <chat-history
      :chat-id="chatId"
      :messages="messageHistory"
      :new-message="newBotMessage"
    />
    <footer class="w-full relative">
      <system-feedback
        :is-open="systemFeedbackIsOpen"
        :text="systemFeedbackText"
        @open-close="handleOpenCloseSystemFeedback"
      />
      <div class="bg-white">
        <div class="max-w-6xl px-2.5 mx-auto inset-x-0">
          <div class="pb-2 relative">
            <chat-prompter
              v-if="isShowingChatPrompter"
              :color="color1"
              @close="handleChatPrompterClose"
            />
            <form class="flex flex-col relative w-full rounded-3xl px-1.5 bg-zinc-100">
              <div class="flex">
                <div class="self-end mb-1.5 flex space-x-1">
                  <div class="flex">
                    <button-component
                      classes="disabled transition rounded-full p-1.5 self-center hover:bg-black/10"
                      tooltip-text="Mais Opções"
                      :style="btnChatPrompterStyles"
                    >
                      <ph-icon
                        icon="PhPlus"
                        :color="iconChatPrompterColor"
                        cursor="inherit"
                        @click="toggleChatPrompter"
                      />
                    </button-component>
                  </div>
                </div>
                <textarea
                  v-model="message"
                  placeholder="Escreva uma mensagem"
                  :readonly="blockUserInteraction"
                  :rows="chatRow"
                  wrap="hard"
                  class="
                    scrollbar-hidden outline-none w-full py-3 px-3 rounded-xl resize-none
                    h-[48px] svelte-141e0sl bg-transparent mr-1
                  "
                  @keyup="handleMessage"
                />
                <div class="self-end mb-1.5 flex space-x-1">
                  <div class="flex">
                    <button-component
                      classes="text-white disabled transition rounded-full p-1.5 self-center bg-zinc-200"
                      tooltip-text="Enviar mensagem"
                      :style="btnSendMessageStyles"
                    >
                      <ph-icon
                        icon="PhPaperPlaneRight"
                        color="#fff"
                        cursor="inherit"
                        @click="sendMessage"
                      />
                    </button-component>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <span class="text-xs text-gray-500 w-full inline-block text-center">
            {{ navigatorName }} pode cometer erros. Considere verificar informações importantes.
          </span>
        </div>
      </div>
    </footer>
  </section>
</template>

<script lang="ts" setup>
import {
  CSSProperties,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue'
import ChatHistory from '@/components/chat-history.vue'
import ButtonComponent from './button-component.vue'
import PhIcon from '@/components/ph-icon.vue'
import { handleChatId, getChatMessages } from '@/utils/message-history'
import eventBus from '@/utils/event-bus'
import useIframeControl from '@/hooks/iframe'
import * as BotService from '@/services/BotService'
import SystemFeedback from './system-feedback.vue'
import ChatPrompter from './chat-prompter.vue'

import {
  IRecognitionError,
  IRecognitionResult,
  ImessageChatBox,
} from '@/types'
import store, {
  setActiveChatId,
  setMessageHistory,
} from '@/store'

type TChat = {
  chatId: string;
  chatHistory: ImessageChatBox[];
}

const message = ref('')
const messageHistory = ref([] as ImessageChatBox[])
const newBotMessage = ref(null as string | null)
const chatId = ref('')
const isMicActive = ref(false)
const chatRow = ref(1)
const blockSendMessage = ref(false)
const userPrompt = ref('')
const viewType = inject('viewType')
const blockUserInteraction = viewType === 'chatPreview'
const newChatInfo = ref({})
const systemFeedbackIsOpen = ref(false)
const systemFeedbackText = ref('')
const navigatorName = ref(store.navigatorName)
const chatBoxStyles = reactive({
  width: '100%',
  left: '0',
})
const getLineHeight = () => {
  const body = document.querySelector('body') as HTMLElement
  const lineHeight = document.defaultView?.getComputedStyle(body,null).getPropertyValue('line-height') || '24';
  return parseInt(lineHeight)
}
const bodyLineHeight = getLineHeight()
const iframe = useIframeControl()
const color1 = ref(store.color1)
const btnSendMessageStyles = ref<CSSProperties>({
  cursor: 'not-allowed',
  backgroundColor: '#c4c4c4',
})
const btnChatPrompterStyles = reactive({
  backgroundColor: 'transparent',
})
const iconChatPrompterColor = ref('#707070')
const isShowingChatPrompter = ref(false)
const loadActiveChat = async (chatId: string) => {
  messageHistory.value = []
  if(!chatId) return
  const chatHistory = await getChatMessages(chatId)
  messageHistory.value = chatHistory.map((message) => ({...message, isLoading: false}))
  scrollToBottom()
  handleNewChatInfo('')
}
const handleActiveChatMessagesUpdated = (messages: string) => {
  messageHistory.value = JSON.parse(messages)
  scrollToBottom()
}
const handleColor1 = (color: string) => {
  color1.value = color
}
const handleChatBoxWidth = () => {
  const isOpen = store.asideMenuIsOpen
  const isFull = viewType === 'chat' && isOpen
  chatBoxStyles.width = isFull ? 'calc(100% - 360px)' : '100%'
  chatBoxStyles.left = isFull ? '360px' : '0'
}
const handleNewChatEvent = () => {
  messageHistory.value = []
  message.value = ''
  chatId.value = ''
  setActiveChatId('')
  resetNewChatInfo()
}
async function handleHistoryItem ({chatId: newMessageId, chatHistory}: TChat) {
  chatId.value = newMessageId
  messageHistory.value = []
  await nextTick()
  messageHistory.value = chatHistory.map((message) => ({...message, isLoading: false}))
  setActiveChatId(newMessageId)
  scrollToBottom()
}
const handleActiveChatBox = (id: string) => {
  const newId = id || ''
  chatId.value = newId
  loadActiveChat(newId)
  setActiveChatId(newId)
}

function handleOpenCloseSystemFeedback() {
  systemFeedbackIsOpen.value = !systemFeedbackIsOpen.value
}

function handleNavigatorName(name: string) {
  navigatorName.value = name
}

onMounted(() => {
  handleChatBoxHeight()
  eventBus.on('copy-text', handleCopyText)
  eventBus.on('edit-message', handleEditMessage)
  eventBus.on('active-chat', handleActiveChatBox)
  eventBus.on('change-color1', handleColor1)
  eventBus.on('toggle-aside-menu', handleChatBoxWidth)
  eventBus.on('new-chat', handleNewChatEvent)
  eventBus.on('load-unique-message', handleHistoryItem)
  eventBus.on('user-info-updated', BotService.updateApiToken)
  eventBus.on('set-system-feedback', setSystemFeedback)
  eventBus.on('active-chat-messages-updated', handleActiveChatMessagesUpdated)
  eventBus.on('change-navigator-name', handleNavigatorName)
})
onUnmounted(() => {
  eventBus.off('copy-text', handleCopyText)
  eventBus.off('edit-message', handleEditMessage)
  eventBus.off('active-chat', handleActiveChatBox)
  eventBus.off('change-color1', handleColor1)
  eventBus.off('toggle-aside-menu', handleChatBoxWidth)
  eventBus.off('new-chat', handleNewChatEvent)
  eventBus.off('load-unique-message', handleHistoryItem)
  eventBus.off('user-info-updated', BotService.updateApiToken)
  eventBus.off('set-system-feedback', setSystemFeedback)
  eventBus.off('active-chat-messages-updated', handleActiveChatMessagesUpdated)
  eventBus.off('change-navigator-name', handleNavigatorName)
})
const handleCopyText = (text: string) => {
  iframe.copyText(text)
  setSystemFeedback('Copiado para área de transferência')
}
const setSystemFeedback = (text: string) => {
  if(!text) return
  systemFeedbackText.value = text
  systemFeedbackIsOpen.value = true
  setSystemFeedbackFadeOut(4)
}
const setSystemFeedbackFadeOut = (time: number) => {
  if(!time) return
  setTimeout(() => {
    systemFeedbackIsOpen.value = false
  }, time * 1000)
}
const handleEditMessage = (text: string) => {
  message.value = text
  handleChatRow()
}
const sendMessage = async (): Promise<void> => {
  if(blockUserInteraction) return
  const content = message.value.trim().replace(/^\n/, '').replace(/\n$/, '')
  if (content === '' || blockSendMessage.value) return
  blockSendMessage.value = true
  userPrompt.value = content
  const additional_kwargs = {
    articles: [],
    id: '',
    created_at: new Date().toISOString(),
    parent_id: '',
  }
  handleMessageHistory({content, role: "user", isLoading: false, additional_kwargs})
  message.value = ''
  clearChatRow()
  const currentChatId = await handleChatId(chatId.value, content)
  if(!currentChatId) {
    blockSendMessage.value = false
    eventBus.emit('set-modal', {
      btn: [{
        text: 'Ok',
        type: 'default',
      }],
      isOpen: true,
      message: `Oops! Ocorreu um erro ao criar uma nova conversa.
        Por favor, envie uma nova mensagem para tentar novamente.
      `,
    })
    messageHistory.value = []
    return 
  }
  sendMessageToBot(content, currentChatId)
  scrollToBottom()
}
const scrollToBottom = async (freeze = false) => {
  const chatHistory = document.querySelector('#history') as HTMLElement
  const {
    scrollHeight,
    scrollTop,
    clientHeight,
  } = chatHistory
  const tolerance = bodyLineHeight * 3
  const userHasScrolled = scrollTop < (scrollHeight - clientHeight - tolerance)
  if(freeze && userHasScrolled) {
    return
  }
  await nextTick()
  chatHistory.scrollTop = scrollHeight
}
const sendMessageToBot = async (userPrompt: string, currentChatId: string): Promise<void> => {
  scrollToBottom()
  const emptyAdditionalKwargs = {
    articles: [],
    id: '',
    created_at: new Date().toISOString(),
    parent_id: '',
  }
  newBotMessage.value = ''
  BotService.sendMessage(
    currentChatId,
    userPrompt,
    (content) => {
      newBotMessage.value = content
      scrollToBottom()
    },
    (additional_kwargs) => {
      if(currentChatId !== chatId.value){
        chatId.value = currentChatId
        newChatInfo.value = {}
      }
      handleMessageHistory({
        content: newBotMessage.value || '',
        role: 'assistant',
        isLoading: false,
        additional_kwargs: additional_kwargs || emptyAdditionalKwargs,
      })
      iframe.sendActiveChatMessages(JSON.stringify(messageHistory.value))
      handleChatHistory(userPrompt)
      newBotMessage.value = null
      blockSendMessage.value = false
    },
    () => {
      newBotMessage.value = null
      handleMessageHistory({
        content: `Desculpe, não foi possível processar sua mensagem.
        Não estou conectado à base de conhecimento ou, por motivos de segurança, essa pergunta não é permitida`,
        role: 'assistant',
        isLoading: false,
        additional_kwargs: emptyAdditionalKwargs,
      })
      scrollToBottom(true)
      blockSendMessage.value = false
    },
    store.articleFilter,
  )
}
const resetNewChatInfo = () => {
  newChatInfo.value = {}
}
const handleNewChatInfo = (title: string) => {
  const newChat = {
    chat_id: chatId.value,
    last_message_date: new Date().toISOString(),
    title,
  }
  newChatInfo.value = newChat
  return newChat
}
const handleChatHistory = (title: string) => {
  if(Object.keys(newChatInfo.value).length > 0) return
  const newChat = handleNewChatInfo(title)
  const chatHistory = [newChat, ...store.chatHistory]
  setMessageHistory(chatHistory)
  iframe.sendChatHistory(JSON.stringify(chatHistory))
  setActiveChatId(chatId.value)
}
const handleMessageHistory = async (messageInfo: ImessageChatBox) => {
  messageHistory.value = [...messageHistory.value, messageInfo]
  return messageHistory.value
}
const handleBtnSendMessage = () => {
  const textareaHasText = message.value.trim() !== ''
  btnSendMessageStyles.value = {
    cursor: !blockSendMessage.value && textareaHasText && !blockUserInteraction ? 'pointer' : 'not-allowed',
    backgroundColor: !blockSendMessage.value && textareaHasText && !blockUserInteraction ? color1.value : '#c4c4c4',
  }
}
const toggleChatPrompter = () => {
  isShowingChatPrompter.value = !isShowingChatPrompter.value
  btnChatPrompterStyles.backgroundColor = isShowingChatPrompter.value ? color1.value : 'transparent'
  iconChatPrompterColor.value = isShowingChatPrompter.value ? '#fff' : '#707070'
}
const handleChatPrompterClose = (frase: string) => {
  toggleChatPrompter()
  if(!frase) return
  message.value = frase
}
const handleMessage = ({ key, shiftKey }: KeyboardEvent): void => {
  const textareaHasText = message.value.trim() !== ''
  handleBtnSendMessage()
  if (!textareaHasText){
    clearChatRow()
    return
  }
  if(key === 'Enter' && !shiftKey && !blockSendMessage.value){
    sendMessage()
    clearChatRow()
  }
  handleChatRow()
}
const handleChatRow = async (): Promise<void> => {
  clearChatRow()
  if(message.value.trim() === '') return
  await nextTick()
  const lineHeight = 24
  const maxVisibleLines = 5
  const textarea = document.querySelector('footer textarea') as HTMLTextAreaElement
  const { scrollHeight } = textarea
  const rows = Math.floor(scrollHeight / lineHeight)
  const finalRowNum = rows > maxVisibleLines ? maxVisibleLines : rows
    textarea.style.height = `${finalRowNum * lineHeight}px`
}
const clearChatRow = (): void => {
  const textarea = document.querySelector('footer textarea') as HTMLTextAreaElement
  textarea.style.height = '48px'
}
// eslint-disable-next-line
const startRecognition = () => {
  if(blockUserInteraction) return
  const SpeechRecognitionConstructor = window.SpeechRecognition || window.webkitSpeechRecognition;
  if(!SpeechRecognitionConstructor) {
    console.error('Seu navegador não suporta a API de reconhecimento de fala')
    return
  }
  isMicActive.value = true
  // eslint-disable-next-line
  const recognition = new ((window.SpeechRecognition || window.webkitSpeechRecognition) as any)();
  recognition.lang = 'pt-BR';
  recognition.start();
  recognition.onresult = (event: IRecognitionResult) => {
    message.value = [...event.results].reduce((acc, result) => {
      const { transcript } = result[0]
      return acc + transcript
    }, '');
    isMicActive.value = false
  };
  recognition.onerror = (event: IRecognitionError) => {
    console.error('Erro no reconhecimento de fala:', event.error)
    isMicActive.value = false
  };
}
const handleChatBoxHeight = () => {
  const query = new URLSearchParams(window.location.search)
  const type = query.get('type')
  if(type === 'chat'){
    const chatBoxSection = document.querySelector('section#chat-box') as HTMLElement
    chatBoxSection.style.height = '100%'
  }
}
watch(chatId, (newMessageId, oldMessageId) => {
  if(newMessageId === oldMessageId) return
  if(!newMessageId) {
    iframe.clearActiveChatId()
  }
  iframe.saveActiveChatId(newMessageId)
})
watch(blockSendMessage, handleBtnSendMessage)
</script>

<style lang="scss" scoped>
section#chat-box {
  position: relative;
  padding: .5rem;
  height: calc(100% - 50px);
  background-color: #fff;
  transition: transform 0.3s cubic-bezier(1, 0, 0, 1) ;
}
textarea {
  font-size: 13px;
  line-height: 24px;
}
textarea::placeholder {
  font-size: 13px;
  line-height: 24px;
}
</style>
