import { createApp } from 'vue'
import PhosphorIcons from "@phosphor-icons/vue"
import App from './App.vue'
import './index.css'
import 'animate.css'
import VueDOMPurifyHTML from 'vue-dompurify-html';

const query = new URLSearchParams(window.location.search)
const type = `${query.get('type') || 'widget'}`
const device = `${query.get('device') || 'desktop'}`
createApp(App)
  .use(PhosphorIcons)
  .use(VueDOMPurifyHTML, {
    namedConfigurations: {
      svg: {
        USE_PROFILES: { svg: true },
      },
      mathml: {
        USE_PROFILES: { mathMl: true },
      },
    },
  })
  .provide('viewType', type)
  .provide('device', device)
  .mount('#app')
 