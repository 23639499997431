<template>
  <widget v-if="type === 'widget'" />
  <chat v-else-if="type === 'chat'" />
  <chat-preview v-else-if="type === 'chatPreview'" />
  <div v-else>
    <h4>Type Not Found</h4>
  </div>
  <messageModal />
</template>

<script lang="ts" setup>
import {
  inject,
  onMounted,
} from 'vue';
import Widget from './views/Widget/index.vue'
import Chat from './views/Chat/index.vue'
import ChatPreview from './views/ChatPreview/index.vue'
import useIframeControl from '@/hooks/iframe';
import { messageEvents } from '@/utils/message-events';
import MessageModal from './components/message-modal.vue';

const type = inject('viewType') as string
onMounted(() => {
  window.addEventListener('message', ({ data }) => messageEvents(data))
  const iframeControl = useIframeControl()
  iframeControl
    .sendReadyStoreStatus()
})
</script>

<style lang="scss">
@font-face {
  font-family: 'Ubuntu';
  src: url(./assets/fonts/ubuntu/Ubuntu-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu';
  src: url(./assets/fonts/ubuntu/Ubuntu-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu';
  src: url(./assets/fonts/ubuntu/Ubuntu-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu';
  src: url(./assets/fonts/ubuntu/Ubuntu-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
  color: #333;
}
*::-webkit-scrollbar {
  width: .5rem;
}
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.scroll-hover:hover::-webkit-scrollbar-thumb,
textarea:hover::-webkit-scrollbar-thumb {
  background-color: #babac0;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 1rem;
}
*::-webkit-scrollbar-button {
  display:none;
}
.text-default, body input, body textarea {
  font-size: 13px;
}
html, body {
  overflow: hidden;
}
.message span {
  pre {
    background: #333;
    border-radius: 6px;
    padding: 8px;
    margin: 8px 0;
    color: #fff;
    code {
      color: inherit;
    }
  }
   ol {
    list-style-type: decimal;
    padding-left: 1rem;
  }
}

</style>
